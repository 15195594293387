<template>
  <div class="menu_container">
    <h1 class="text-center">注文情報入力</h1>
    <form>
      <div class="date_container">
        <div class="date_title">
          <h3 class="">利用方法<span>※必須</span></h3>
          <p>※当日の予約対応、当日の店頭販売はしておりません。</p>
        </div>
        <div class="date_main">
          <div class="radio_input">
            <label>
              <input type="radio" value="店頭受け取り" v-model="where"/>店頭受け取り
              <p>※お持ち帰りの場合は、消費税分サービスさせていただきます。受け渡し時間は、午前中のみとなります。
              </p>
            </label>
          </div>
          <div class="radio_input">
            <label>
              <input type="radio" value="delivery" v-model="where"/>配達
            </label>
          </div>
        </div>
        <div v-if="where==='delivery'" class="date_main">
          <div class="date_title">
            <h3 class="">配達先</h3>
          </div>
          <div class="date_input">
            <p for="postal-code">郵便番号</p>
            <input
              class="form-control_post"
              type="text"
              id="postal-code"
              v-model="postcode"
              v-on:blur="fetchAddress()"
            >
        </div>
        <div class="date_input">
            <p for="address">住所</p>
            <input class="form-control" type="text" id="address" v-model="address">
        </div>
        <div class="deliveryFeeCalc">
          <div>
            <btn class="back_btn" @click="calculateFee">配達料を計算する</btn>
          </div>
          <div v-if="distance>0">
            <p>配達距離：{{ distance.toFixed(1) }}km</p>
            <p>配達料金：{{ deliveryFee }}円</p>
            <p>※配達距離は、Google Map APIをもとに算出しています。
            </p>
          </div>
        </div>
        </div>
      </div>
      <div class="date_container">
        <div class="date_title">
          <h3 class="">受取日時<span>※必須</span></h3>
          <p>毎週火曜日と12/29-1/5は定休日となっております。</p>
        </div>
        <div class="date_main">
          <div class="date_input">
            <input type="date" v-model="date" @input="validateDate">
          </div>
          <p class="time_title">希望時間</p>
          <div class="time_input">
            <input type="time" v-model="time_start">
            <p>～</p>
            <input type="time" v-model="time_end">
          </div>
          <p class="">※30分から1時間ほど配達時間に余裕を持たせていただきますようお願いいたします。</p>
        </div>
      </div>
      <div class="date_container">
        <div class="date_title">
          <h3 class="">購入者情報<span>※必須</span></h3>
        </div>
        <div class="date_main">
          <div class="date_input">
            <p>お名前</p>
            <input
              type="text"
              v-model="firstname"
              label="First name"
            >
          </div>
          <div class="date_input">
            <p>メールアドレス</p>
            <input
              v-model="email"
              type="email"
              label="E-mail"
            >
          </div>
          <div class="date_input">
            <p>電話番号</p>
            <input type="tel" v-model="tel">
          </div>
          <div class="radio_input">
            <p>支払い情報</p>
            <label for=1>
              <input type="radio" id=1 value="現金" v-model="howtopay" />現金で支払う
            </label>
            <label for=2>
              <input type="radio" id=2 value="PayPay" v-model="howtopay" />PayPayで支払う
            </label>
            <label for=3>
              <input type="radio" id=3 value="請求書" v-model="howtopay" />請求書で支払う
            </label>
          </div>
          <div class="date_input request">
            <div v-if="howtopay==='請求書'" class="bill">
              <p>請求書の御宛名をご記入ください ※法人のみ可</p>
              <input type="text" v-model="bill">
            </div>
            <div v-if="howtopay==='現金'||howtopay==='PayPay'" class="bill">
              <p>領収書が必要な場合は御宛名をご記入ください</p>
              <input type="text" v-model="bill">
            </div>
          </div>
          <div class="date_input request">
            <p>お店への要望 
              <br/>※購入者と当日の受取担当者が異なる場合は、こちらに名前と電話番号を追記してください
              <br/>※おむすび弁当の選択おむすびはこちらにご記入ください。
            </p>
            <input type="text" v-model="request">
          </div>
        </div>
      </div>
      <div v-if="where" class="date_container">
        <div class="date_title">
          <h3 class="">注文商品</h3>
        </div>
        <div class="date_main">
          <table>
            <tr v-for="contentofCart in cart" :key="contentofCart">
              <td>{{ contentofCart.name }}</td>
              <td>×{{ contentofCart.count }}</td>
            </tr>
          </table>
          <table>
            <tr v-for="contentofCart in cartofPack" :key="contentofCart">
              <td>{{ contentofCart.name }}</td>
              <td>×{{ contentofCart.count }}</td>
            </tr>
            <div v-if="distance > 0">
              <p>配達料</p>
            </div>
          </table>
            <h3  v-if="where==='店頭受け取り'" class="total_price">お支払い金額：{{ total + totalofPack }}円</h3>
            <h3  v-if="where==='delivery'" class="total_price">お支払い金額：{{ total + totalofPack + tax + deliveryFee}}円
              <span><br/>（税抜き金額 8%対象：{{ total }}円）</span>
              <span><br/>（税抜き金額 10%対象：{{ totalofPack }}円）</span>
              <span><br/>（配達料（税抜）：{{ deliveryFee }}円）</span>
              <span><br/>（消費税8%：{{ tax8 }}円）</span>
              <span><br/>（消費税10%：{{ tax10 }}円）</span>
            </h3>
        </div>
      </div>
    </form>
      <div class="next_btn_container">
        <btn class="next_btn" @click="confirmInformation">▶ 入力した情報を確認する</btn>
      </div>
  </div>
</template>
<script>
import {onMounted, inject, toRef, ref} from "vue";
import { useRouter } from "vue-router";
import { Core as YubinBangoCore } from 'yubinbango-core2'
import { Loader } from '@googlemaps/js-api-loader';

export default {
  name: "CustomerForm",
  setup(){
    const { formState, } = inject("formStore");
    const { total, menuState, calculateTotal, totalofPack, calculateTotalofPack } = inject("menuStore");
    const cart = toRef(menuState, "cart");
    const cartofPack = toRef(menuState, "cartofPack");
    const info = toRef(formState, "info");
    const firstname = ref();
    const email = ref();
    const where = ref();
    const howtopay = ref();
    const postcode = ref();
    const address = ref("");
    const date = ref();
    const time_start = ref();
    const time_end = ref();
    const tel = ref();
    const bill = ref("");
    const request = ref("");
    const router = useRouter();
    const tax = ref(0);
    const tax8 = ref(0);
    const tax10 = ref(0); 
    const distance = ref(0);
    const deliveryFee = ref(0);
    const caluculateFlg = ref(true);
    // const orderAmount = ref(total.value + totalofPack.value + tax.value);

    const nameRules = [
      v => !!v || 'Name is required',
      v => v.length <= 10 || 'Name must be less than 10 characters',
    ];
    const emailRules = [
      v => !!v || 'E-mail is required',
      v => /.+@.+/.test(v) || 'E-mail must be valid',
    ];
    // 郵便番号から住所を取得
    const fetchAddress = () => {
        new YubinBangoCore(postcode.value, function(addr) {
          // region=都道府県, locality=市区町村, street=町域
          const addressRaw = addr.region + addr.locality + addr.street
          address.value = addressRaw
        })
    };

    const isValidTime = (time) =>{
      const [hours, minutes] = time.split(':').map(Number);
      if (isNaN(hours) || isNaN(minutes)) return false;

      const timeInMinutes = hours * 60 + minutes;
      const startTimeInMinutes = 5 * 60; // 5:00
      const endTimeInMinutes = 16 * 60; // 16:00

      return timeInMinutes >= startTimeInMinutes && timeInMinutes <= endTimeInMinutes;
    }

    const confirmInformation = () => {
      const selectedDate = new Date(date.value);
      selectedDate.setHours(0, 0, 0, 0);
      const day = selectedDate.getDay();
      const month = selectedDate.getMonth() + 1;
      const dayOfMonth = selectedDate.getDate();
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const dayAfterTomorrow = new Date(today);
      dayAfterTomorrow.setDate(today.getDate() + 2);


      if(!where.value){
        alert("利用方法を入力してください");
      } else if(where.value==="delivery" && !postcode.value){
        alert("郵便番号を入力してください");
      } else if(where.value==="delivery" && !address.value){
        alert("住所を入力してください");
      } else if(!date.value){
        alert("受取日を入力してください");
      } else if(!time_start.value){
        alert("受取時間を入力してください");
      } else if(!time_end.value){
        alert("受取時間を入力してください");
      } else if(!firstname.value){
        alert("お名前を入力してください");
      } else if(!email.value){
        alert("メールアドレスを入力してください");
      } else if(!tel.value){
        alert("電話番号を入力してください");
      } else if(!howtopay.value){
        alert("支払い方法を入力してください");
      } else if (day === 2) {
        alert('火曜日は定休日となっております。');
      } else if ((month === 12 && dayOfMonth >= 29) || (month === 1 && dayOfMonth <= 5)) {
        alert('年末年始は定休日となっております。');
      } else if(month === 4 && dayOfMonth >= 15 && dayOfMonth <= 19){
        alert('4/15-4/19は休みとなっております。');
      }else if (selectedDate < dayAfterTomorrow) {
        alert('受取日は明後日以降でお願いいたします。');
      } else if(where.value==="delivery" && caluculateFlg.value){
        alert('「配達料を計算する」ボタンを押してください。');
      } else if(!isValidTime(time_start.value)){
        alert('受取の希望時間は、5:00-16:00の間を入力してください');
      }else if(!isValidTime(time_end.value)){
        alert('受取の希望時間は、5:00-16:00の間を入力してください');
      }else{
        info.value.push({
          where: where.value,
          postcode: postcode.value,
          address: address.value,
          date: date.value,
          time_start: time_start.value,
          time_end: time_end.value,
          firstname: firstname.value,
          email: email.value,
          tel: tel.value,
          howtopay: howtopay.value,
          bill: bill.value,
          request: request.value,
          cart: cart.value,
          total: total.value,
          cartofPack: cartofPack.value,
          totalofPack: totalofPack.value,
          tax: tax.value,
          deliveryFee: deliveryFee.value,
          tax8: tax8.value,
          tax10: tax10.value,
          });
          router.push("/confirm")
      }
    };

  const calculateTax = () => {
    if(where.value==='delivery'){
    tax8.value = Math.floor(total.value * 0.08)
    tax10.value = totalofPack.value * 0.1 + Math.floor(deliveryFee.value * 0.1)
    tax.value = tax8.value + tax10.value
    }
  }

  const calculateFee = async () => {
      const loader = new Loader({
        apiKey: 'AIzaSyCGU_TOgyPTHLBs5eAh4hElTDvIcF3dXGE',
        version: 'weekly',
      });

      const google = await loader.load();

      if (!google.maps || !google.maps.Geocoder) {
          throw new Error('Google Maps API failed to load.');
        }

      const geocoder = new google.maps.Geocoder();
      const tokyoStation = { lat: 35.754681393896604, lng: 139.73959891378848 };

      geocoder.geocode({ address: address.value }, (results, status) => {
        if (status === 'OK' && results[0]) {
          const destination = results[0].geometry.location;
          const service = new google.maps.DistanceMatrixService();

          service.getDistanceMatrix(
            {
              origins: [tokyoStation],
              destinations: [destination],
              travelMode: 'DRIVING',
              avoidTolls: true,  // Avoid toll roads
              avoidHighways: true,
            },
            (response, status) => {
              if (status === 'OK' && response.rows[0].elements[0].status === 'OK') {
                distance.value = response.rows[0].elements[0].distance.value / 1000; // meters to km
                calculateDeliveryFee();
                calculateTax();
              } else {
                alert('Error calculating distance.');
              }
            }
          );
          caluculateFlg.value = false
        } else {
          alert('住所を正しく入力して、再度ボタンを押してください');
        }
      });
    }
    const calculateDeliveryFee = () => {
        const additionalKm = Math.floor(distance.value);
        const additionalFee = 500 + additionalKm * 150;
        const minimumOrderAmount = 5000 + (additionalKm * 1500);
        if (total.value + totalofPack.value >=  minimumOrderAmount) {
          deliveryFee.value = 0;
        } else if ((total.value + totalofPack.value + additionalFee) > minimumOrderAmount){
          deliveryFee.value = minimumOrderAmount - total.value - totalofPack.value;
        } else{
          deliveryFee.value = additionalFee;
        }
      }

  onMounted(() => {
    calculateTotal(cart.value);
    calculateTotalofPack(cartofPack.value);
    calculateTax();
    if(info.value[0]){
      where.value = info.value[0]["where"];
      postcode.value = info.value[0]["postcode"];
      address.value = info.value[0]["address"];
      date.value = info.value[0]["date"];
      time_start.value = info.value[0]["time_start"];
      time_end.value = info.value[0]["time_end"];
      firstname.value = info.value[0]["firstname"];
      email.value = info.value[0]["email"];
      tel.value = info.value[0]["tel"];
      howtopay.value = info.value[0]["howtopay"];
      bill.value = info.value[0]["bill"];
      request.value = info.value[0]["request"];
      cart.value = info.value[0]["cart"];
      total.value = info.value[0]["total"];
      cartofPack.value = info.value[0]["cartofPack"];
      totalofPack.value = info.value[0]["totalofPack"];
      tax.value = info.value[0]["tax"];
      deliveryFee.value = info.value[0]["deliveryFee"];
      tax8.value = info.value[0]["tax8"];
      tax10.value = info.value[0]["tax10"];
    }
  })

    return{
      firstname,
      email,
      nameRules,
      emailRules,
      where,
      howtopay,
      total,
      cart,
      fetchAddress,
      postcode,
      address,
      confirmInformation,
      date,
      time_start,
      time_end,
      tel,
      request,
      bill,
      cartofPack,
      totalofPack,
      tax,
      calculateFee,
      calculateDeliveryFee,
      distance,
      deliveryFee,
      tax8,
      tax10
    }
  }
}
</script>
<style>
.menu_container{
  padding-top: 100px;
  width: 75%;
  font-family: "Noto Sans JP";
}
.text-center{
  letter-spacing: 5px;
}
.date_container{
  padding: 3vw;
  border-bottom:3px solid #333;
}
.date_title{
  padding:1vw;
  & h3{
    font-size:20px;
    font-weight: 550;
    letter-spacing: 5px;
  }
  & span{
    font-size:14px;
    font-weight: 550;
    letter-spacing: 5px;
    color: red;
  }
  & p{
    margin: 1vw;
    margin-bottom: 0;
  }
}
.date_main{
  padding:1vw;
  & p{
    font-size:14px;
    font-weight: 500;
    letter-spacing: 5px;
  }
  & table{
    font-size:14px;
    font-weight: 500;
    letter-spacing: 5px;
  }
  & span{
    font-size: 14px;
    font-weight: 500;
  }
}
.date_input{
  padding: 1vw;
  & input{
    padding:1vw;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 5px;
    border-radius: 5px;
    border: 2px solid #333;
    color:#333;
    width: 100%;
  }
}.time_input{
  padding: 1vw;
  justify-content: space-between;
  display: flex;
  align-items: center;
  & input{
    padding:1vw;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 5px;
    border-radius: 5px;
    border: 2px solid #333;
    color:#333;
    width: 40%;
  }
}
.request{
  & input{
    width:100%;
  }
}
.bill{
  & input{
    padding:1vw;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 5px;
    border-radius: 5px;
    border: 2px solid #333;
    color:#333;
    width: 100%;
  }
}
.radio_input{
  padding: 1vw;
  & input{
    width: 24px;
    height: 24px;
    align-items: center;
    display: flex;
  }
  & label{
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 5px;
    align-items: center;
    display: flex;
  }
}
.form-control{
  width:80%;
}
.total_price{
  text-align: right;
  font-size:2vw;
  font-weight: 500;
  letter-spacing: 2px;
}
.next_btn_container {
  text-align: center;
  padding-top: 5vw ;
}
.next_btn {
  display: inline-block;
  padding: 8px 30px 10px 46px;
  border-radius: 22px;
  font-size: 20px;
  background: red;
  background-repeat: no-repeat;
  background-position: 16px 50%;
  background-size: 18px 18px;
  text-align: center;
  font-weight: 700;
  line-height: 1.4;
  cursor: pointer;
  color: white;
  transition: color 0.15s ease-in;
}
input[type=date]::-webkit-calendar-picker-indicator {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
}
input[type="date"]::-webkit-inner-spin-button{
  -webkit-appearance: none;
}

input[type="date"]::-webkit-clear-button{
  -webkit-appearance: none;
}

input[type="date"]{
 
  position: relative;
}

input[type=time]::-webkit-calendar-picker-indicator {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
}
input[type="time"]::-webkit-inner-spin-button{
  -webkit-appearance: none;
}

input[type="time"]::-webkit-clear-button{
  -webkit-appearance: none;
}

input[type="time"]{
 
  position: relative;
}
.deliveryFeeCalc{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

</style>